import React from "react"
import { useClasses } from '../lib/ReactUtils'
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CTA from '../components/CTA'
import '../styles/TwoCols'


export default function FeaturesPage() {
  const root = useClasses()
  return (
    <Layout>
      <SEO title='Features' description='An overview of the amazing features of OneOffice'/>
      <main className="main" ref={root}>

        <div className="heading-separator"/>
        <h1 className="title">Features</h1>
        <h2 className="subtitle">Best-in-class productivity & collaboration for effective teams</h2>

        <div className="twocols">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/features/oo-dashboard.png" alt="Dashboard"/>
          </div>
          <div className="twocols-col-text-left">
            <div className="subheading">Dashboard</div>
            <div className="heading">Start on the right foot</div>
            <div className="description">
              <p>How you start your work day empowers your productivity.</p>
              <p>See at a glace what you missed, tasks ahead of you, and any upcoming meetings.</p>
              <p>Someone mentioned you in a comment? Don't forget to respond</p>
            </div>
          </div>
        </div>

        <div className="twocols">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/features/oo-files-comments.jpg" alt="Files Storage"/>
          </div>
          <div className="twocols-col-text-right">
            <div className="subheading">Files Storage</div>
            <div className="heading">Your cloud drive</div>
            <div className="description">
              <p>Organize your files and resources on your personal cloud drive.</p>
              <p>With built-in search you can locate your work in a breeze.</p>
              <p>Securely share files and folders with colleagues and customers.</p>
            </div>
          </div>
        </div>


        <div className="twocols">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/features/oo-document.png" alt="Documents Editor"/>
          </div>
          <div className="twocols-col-text-left">
            <div className="subheading">Documents</div>
            <div className="heading">Edit online</div>
            <div className="description">
              <p>Armed with the best online editor on the planet, you can work on documents collobaritvely.</p>
              <p>Documents are secured on the server and cannot be copied when you share with customers or partners.</p>
            </div>
          </div>
        </div>


        <div className="twocols">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/features/oo-mail.png" alt="Email Client"/>
          </div>
          <div className="twocols-col-text-right">
            <div className="subheading">Email</div>
            <div className="heading">Stay in Touch</div>
            <div className="description">
              <p>OneOffice comes with secured email server with best-in-class encryption.</p>
              <p>View your emails online with our optimized email viewer or on your mobile device on the go.</p>
            </div>
          </div>
        </div>

        <CTA/>


        <div className="twocols">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/features/oo-weekly.jpg" alt="Weekly Calendar"/>
          </div>
          <div className="twocols-col-text-left">
            <div className="subheading">Calendar</div>
            <div className="heading">Get Organized!</div>
            <div className="description">
              <p>The most effective team players need the most efficient calendar to stay on top!</p>
              <p>With smart notification and email reminders your attendees will never forget to join.</p>
              <p>Automatically create video calls to keep track of all communication.</p>
            </div>
          </div>
        </div>

        <div className="twocols">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/features/oo-deck.jpg" alt="OneOffice Deck"/>
          </div>
          <div className="twocols-col-text-right">
            <div className="subheading">Tasks</div>
            <div className="heading">Kanban style projects</div>
            <div className="description">
              <p>Who said projects need to be complex? With Kanban cards & lists your productivity will shoot way up!</p>
              <p>Attach documents or video calls / chats to your projects for real-time collaboration.</p>
              <p>Automated notifications will keep you on track on due dates and deliverables.</p>
            </div>
          </div>
        </div>


        <div className="twocols">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/features/oo-meet-call.jpg" alt="OneOffice Meet"/>
          </div>
          <div className="twocols-col-text-left">
            <div className="subheading">Calls</div>
            <div className="heading">Secure Video & Chats</div>
            <div className="description">
              <p>Video / Audio calls and chats are the backbone of modern team communication. With OneOffice all your communications go through your own server for max security.</p>
              <p>Whether you have an ad-hoc meeting or weekly ones, your communications are documented and stored for easy access.</p>
              <p>On the go? Our mobile call app with ring when someone tries to reach you.</p>
            </div>
          </div>
        </div>


        <div className="twocols">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/index/devices.png" alt="Mobile Apps"/>
          </div>
          <div className="twocols-col-text-right">
            <div className="subheading">Mobile First</div>
            <div className="heading">Take your office on the go</div>
            <div className="description">
              <p>Our web apps are optimized for all devices and screen sizes.</p>
              <p>On the go? Our user-friendly mobile apps send you notifications of latest events. The Meet app rings whenever a meeting starts or someone is trying to reach you.</p>
              <p>Upload, download or edit files, anywhere and anytime -- even on the plane.</p>
            </div>
          </div>
        </div>


        <div className="twocols">
          <div className="twocols-col-image">
            <StaticImage className="twocols-image" src="../images/features/oo-erp-main.jpg" alt="OneOffice ERP"/>
          </div>
          <div className="twocols-col-text-left">
            <div className="subheading">ERP</div>
            <div className="heading">Modern Resource Planning</div>
            <div className="description">
              <p>Many of the existing legacy ERPs were built with archaic technologies, translating into upgrade headaches and costs, and lengthy employee training.</p>
              <p>With OneOffice ERP you hit the ground running with all modules in one go: Accounting, CRM, Payroll, Selling, Projects, Services etc.</p>
              <p>Your company will be more efficient, data will be visible real-time and business decisions will be well-grounded.</p>
            </div>
          </div>
        </div>

        <CTA variant={1}/>
        
      </main>
    </Layout>

  )
}

